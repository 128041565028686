import React, { PureComponent, useEffect, useState } from 'react';
import {
  Card, CardBody, Col, 
} from 'reactstrap';


// config
import fetch from '../../../../config/service';
import apiCalls from '../../../../config/apiCalls';
import config from '../../../../config/config';
import { useNavigate } from 'react-router-dom';

import { useForm, Controller } from 'react-hook-form';
import { Button } from 'primereact/button';
// import { Card } from 'primereact/card';
import DoInputField from '../../../Form/Fields/DoInputField';
import DoDateField from '../../../Form/Fields/DoDateField';
import DoPasswordField from '../../../Form/Fields/DoPasswordField';
import DoTextareaField from '../../../Form/Fields/DoTextareaField';
import DoFileUplaod from '../../../Form/Fields/DoFileUpload';
import showToasterMessage from '../../../UI/ToasterMessage/toasterMessage';
import DoRadioButtons from '../../../Form/Fields/DoRadioButtons';
import { Skeleton } from 'primereact/skeleton';


const Profile = (props) => {

  const navigate = useNavigate();
 
  const profileFields = [
    {
      "name": "firstName",
      "type": "text",
      "placeholder": "First Name",
      "label": "First Name",
      "width": 110,
      "id": "firstName",
    },
    {
      "name": "lastName",
      "type": "text",
      "placeholder": "Last Name",
      "label": "Last Name",
      "width": 110,
      "id": "lastName",
    },
    {
      "name": "dateOfBirth",
      "type": "date",
      "placeholder": "Date Of Birth",
      "label": "Date Of Birth",
      "width": 110,
      "id": "dateOfBirth",
    },
    {
      "name": "email",
      "type": "email",
      "placeholder": "Email",
      "label": "Email",
      "width": 110,
      "id": "email",
    },
    {
      "name": "phoneNumber",
      "type": "text",
      "placeholder": "Phone Number",
      "label": "Phone Number",
      "width": 110,
      "id": "phoneNumber",
    },
    {
      "name": "emergencyContact",
      "type": "text",
      "placeholder": "Emergency Contact",
      "label": "Emergency Contact",
      "width": 110,
      "id": "emergencyContact",
    },
    {
      "name": "joinDate",
      "type": "date",
      "placeholder": "Date Of Join",
      "label": "Date Of Join",
      "width": 110,
      "id": "joinDate",
      "isDisable": true
    },
    {
      "name": "companyEmail",
      "type": "email",
      "placeholder": "Company Email",
      "label": "Company Email",
      "width": 110,
      "id": "companyEmail",
      "isDisable": true
    },
    {
      "name": "employeeId",
      "type": "text",
      "placeholder": "Employee ID",
      "label": "Employee ID",
      "width": 110,
      "id": "employeeId",
      "isDisable": true
    },
    {
      "name": "fatherName",
      "type": "text",
      "placeholder": "Father's Name",
      "label": "Father's Name",
      "width": 110,
      "id": "fatherName",
    },
    {
      "name": "motherName",
      "type": "text",
      "placeholder": "Mother's Name",
      "label": "Mother's Name",
      "width": 110,
      "id": "motherName",
    },
    {
      "name": "spouseName",
      "type": "text",
      "placeholder": "Spouse's Name",
      "label": "Spouse's Name",
      "width": 110,
      "id": "spouseName",
    },
    {
      "name": "bankName",
      "type": "text",
      "placeholder": "Bank Name",
      "label": "Bank Name",
      "width": 110,
      "id": "bankName",
    },
    {
      "name": "branch",
      "type": "text",
      "placeholder": "Branch Name",
      "label": "Branch Name",
      "width": 110,
      "id": "branch",
    },
    {
      "name": "accountNo",
      "type": "text",
      "placeholder": "Bank Account No.",
      "label": "Bank Account No.",
      "width": 110,
      "id": "accountNo",
    },
    {
      "name": "ifscCode",
      "type": "text",
      "placeholder": "IFSC Code",
      "label": "IFSC Code",
      "width": 110,
      "id": "ifscCode",
    },
    {
      "name": "uanNumber",
      "type": "text",
      "placeholder": "UAN Number",
      "label": "UAN Number",
      "width": 110,
      "id": "uanNumber",
    },
    //KEEP
    // {
    //   "name": "laptopSNO",
    //   "type": "text",
    //   "placeholder": "Laptop S.No",
    //   "label": "Laptop S.No",
    //   "width": 110,
    //   "id": "laptopSNO",
    // },
    {
      "name": "skypeId",
      "type": "text",
      "placeholder": "Skype Id",
      "label": "Skype Id",
      "width": 110,
      "id": "skypeId",
    },
    {
      "name": "bitbuketId",
      "type": "text",
      "placeholder": "BitBucket Id",
      "label": "BitBucket Id",
      "width": 110,
      "id": "bitbuketId",
    },
    {
      "name": "aadharcardNumber",
      "type": "text",
      "placeholder": "Aadhar No",
      "label": "Aadhar No",
      "width": 110,
      "id": "aadharcardNumber",
    },
    {
      "name": "pancardNumber",
      "type": "text",
      "placeholder": "PAN",
      "label": "PAN",
      "width": 110,
      "id": "pancardNumber",
    },
    {
      "name": "gender",
      "type": "radio",
      "placeholder": "gender",
      "label": "Gender",
      "width": 110,
      "id": "gender",
      "options": [
        { label: "Female", value: "Female" },
        { label: "Male", value: "Male" },
      ],
    },
    {
      "name": "temporaryAddress",
      "type": "textarea",
      "placeholder": "Temporary Address",
      "label": "Temporary Address",
      "width": 110,
      "id": "temporaryAddress",
    },
    {
      "name": "permanentAddress",
      "type": "textarea",
      "placeholder": "Permanant Address",
      "label": "Permanant Address",
      "width": 110,
      "id": "permanentAddress",
    },
  ]

  const [userData,setUserData] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  let modifiedResponse;

  useEffect(()=>{
    getUserData();
  },[]);



  let {
    handleSubmit, 
    reset,   
    setValue,
    formState: { errors },
    control,
} = useForm();

  const convertDate = (dateStr) => {
    const date = new Date(dateStr);
    return date;
  };
 
  // get data from server based on Id
  const getUserData = async () => { 
    setIsLoading(true);   
    if (localStorage.getItem('loginCredentials')) {
      let user = JSON.parse(localStorage.getItem('loginCredentials'));     
      let Url = `${apiCalls.employees}/${user._id}`;
      return fetch('GET', Url)
        .then(async (response) => {
          if (response && response.details) {
            setUserData({...response.details});

            modifiedResponse = Object.assign({}, response.details);
            modifiedResponse.dateOfBirth = convertDate(response.details.dateOfBirth) 
            modifiedResponse.joinDate = convertDate(response.details.joinDate)       
            
            await localStorage.setItem('loginCredentials', JSON.stringify(user));
            if (response.details.photo) {
              updateTopbarData()
            }
             reset(modifiedResponse);
             setIsLoading(false);

          } else if (response && response.errorMessage) {
            setIsLoading(false);
          }
        }).catch((err) => {
          return err;
        });
    }
    else {
      return;
    }
  }
  const updateTopbarData = () => {
  }
  const getFileName = (file) => {
    updateTopbarData();    
  }

  const onClickUpdate = (data) => {
    updateProfile({...data});
  }

  const updateProfile = (formData) => {
    if (formData) {
      // delete formData.email;
      delete formData.password;
      let Url;
      if (localStorage.getItem('loginCredentials')) {
        let user = JSON.parse(localStorage.getItem('loginCredentials'));
        Url = `${apiCalls.employees}/updateEmployee/${user._id}`;
      }
      return fetch('PUT', Url, formData)
        .then(async (response) => {
          if (response && response.respCode && response.respCode === 205) {
            showToasterMessage(response.respMessage, 'success');            
            getUserData();
          } else if (response && response.errorMessage) {
            showToasterMessage(response.errorMessage, 'error');
          }
        }).catch((err) => {
          return err;
        });

    } else {
      return;
    }

  }

  const handleBack = () => {
    navigate(`/${config.defaultScreen}`);
  };

  const resetData = () => {
    let fields = profileFields;
    fields.forEach((item) => {
      let currentValue = userData[item.name];
      if (currentValue) {
        setValue(item.name, currentValue)
      } else {
        setValue(item.name, '')
      }
    })
  }

  return (
    <Col md={12} lg={12}>
      <Card>
        <CardBody>
          <div className="card__title">
            <h5 className="bold-text">Profile</h5>
          </div>
          <form onSubmit={handleSubmit(onClickUpdate)}>

            <div className="row">
              {profileFields && profileFields.length > 0 ? profileFields.map((item, index) => (
                <div className="col-sm-12 col-md-6 pb-2" key={index}>
                  {isLoading ? (
                    <Skeleton className="mb-2" height="3.5rem" borderRadius="10px" />
                  ) : (
                    (item.type === 'text' || item.type === 'email' || item.type === 'url') ? (
                      <Controller
                        name={item.name}
                        control={control}
                        render={({ field, fieldState }) => (
                          <DoInputField
                            input={field}
                            id={item.id}
                            name={item.name}
                            label={item.label}
                            field={field}
                            type={"text"}
                            fieldState={fieldState}
                            errors={errors}
                            placeholder={item.placeholder}
                            isDisable={item.isDisable}
                          />
                        )}
                      />
                    ) : item.type === 'date' ? (
                      <Controller
                        name={item.name}
                        control={control}
                        render={({ field, fieldState }) => (
                          <DoDateField
                            input={field}
                            id={item.id}
                            name={item.name}
                            field={field}
                            fieldState={fieldState}
                            errors={errors}
                            placeholder="date"
                            label={item.label}
                            isDisable={item.isDisable}
                          />
                        )}
                      />
                    ) : item.type === 'radio' ? (
                      <Controller
                        name={item.name}
                        control={control}
                        render={({ field, fieldState }) => (
                          <DoRadioButtons
                            markReq={item.required}
                            options={item.options}
                            input={field}
                            id={item.id}
                            name={item.name}
                            field={field}
                            label={item.label}
                            fieldState={fieldState}
                            errors={errors}
                            item={item}
                            isDisable={item.isDisable}
                          />
                        )}
                      />
                    ) : item.type === 'password' ? (
                      <Controller
                        name={item.name}
                        control={control}
                        render={({ field, fieldState }) => (
                          <DoPasswordField
                            input={field}
                            id={item.id}
                            name={item.name}
                            field={field}
                            fieldState={fieldState}
                            errors={errors}
                            placeholder={item.placeholder}
                            isDisable={item.isDisable}
                          />
                        )}
                      />
                    ) : item.type === 'textarea' ? (
                      <Controller
                        name={item.name}
                        control={control}
                        render={({ field, fieldState }) => (
                          <DoTextareaField
                            input={field}
                            id={item.id}
                            name={item.name}
                            field={field}
                            fieldState={fieldState}
                            errors={errors}
                            placeholder={item.placeholder}
                            label={item.label}
                            isDisable={item.isDisable}
                          />
                        )}
                      />
                    ) : null
                  )}
                </div>
              )) : null}
              <div className="col-sm-12 col-md-6">
                {isLoading ? (
                  <Skeleton className="mb-2" height="3.5rem" borderRadius="10px" />
                ) : (
                  <div className="form__form-group">
                    <span className="form__form-group-label">Profile Image</span>
                    <div className="form__form-group-field">
                      <Controller
                        name="photo"
                        control={control}
                        render={({ field, fieldState }) => (
                          <DoFileUplaod
                            field={field}
                            url="uploads?uploadWhileCreate=true&uploadPath=employees"
                            type="profile"
                            acceptType="image/*"
                            showPreview={false}
                            userClickUpload={true}
                          />
                        )}
                      />
                    </div>
                  </div>
                )}
              </div>
            </div>


            <div className='d-flex mt-3 justify-content-end'>
              {isLoading ? (
                <Skeleton className="mb-2" height="3.5rem" width="20rem" borderRadius="10px" />
              ) : (
                <>
                  <Button type="button" size='sm' color='primary' label='Back' className='me-3' onClick={handleBack} />
                  <Button type="button" size='sm' color='primary' label='Reset' className='me-3' onClick={() => resetData()} />
                  <Button type='submit' size='sm' color='primary' label='Update' />
                </>
              )}
            </div>


          </form>
        </CardBody>
      </Card>
    </Col >
  );
}




export default Profile;