import _ from 'lodash';

const sortInnerArrays = (obj) => {
    const sortedObj = { ...obj };

    for (const key in sortedObj) {
        if (Array.isArray(sortedObj[key])) {
            sortedObj[key].sort();
        }
    }
    return sortedObj;
};

const comparePermissions = (previousData, currentData) => {
    let oldPermissions = {};
    const newPermissions = {};

    if (previousData) {
        for (const [key, value] of Object.entries(previousData)) {
            if (typeof value === "object") {
                oldPermissions[key] = value.type;
                oldPermissions[key + 'Actions'] = value.actions;
            }
        }
    }
    if (currentData) {
        let filteredEntries = Object.entries(currentData).filter(([key]) =>
            Object.entries(oldPermissions).some(([prevKey]) => prevKey === key)
        );
        filteredEntries.forEach(entry => {
            const [key, value] = entry;
            newPermissions[key] = value;
        });
    }
    let sortedOldPer = sortInnerArrays(oldPermissions);
    let sortedNewPer = sortInnerArrays(newPermissions);
    return _.isEqual(newPermissions, oldPermissions);
};

export default {
    getBooleanValue: (field, currentValue, previousDataObject, type, newData) => {
        if (type === 'Roles' && previousDataObject) {
            let previousData = previousDataObject['permissions'];
            if (!comparePermissions(previousData, newData)) {
                return false;
            }
        }

        const previousValue = previousDataObject[field?.name];
        const isArrayofObjects = (array) => array?.length > 0 && typeof array[0] === 'object';
        const isArrayofStrings = (array) => array?.length > 0 && typeof array[0] === 'string';

        // Handle boolean comparisons
        if (typeof currentValue === 'boolean' || typeof previousValue === 'boolean') {
            return currentValue === previousValue;
        }

        // Handle string comparisons
        else if (typeof currentValue === 'string' || typeof previousValue === 'string') {
            const trimmedCurrentValue = currentValue?.toString()?.trim();
            const trimmedPreviousValue = (previousValue != undefined || previousValue != null) ? previousValue : '';
            return trimmedCurrentValue === trimmedPreviousValue;
        }

        // Handle array of strings
        else if (isArrayofStrings(currentValue) || isArrayofStrings(previousValue)) {
            const addedElements = isArrayofStrings(currentValue) && currentValue?.filter(item => !previousValue?.includes(item));
            const removedElements = isArrayofStrings(previousValue) && previousValue?.filter(item => !currentValue?.includes(item));
            return !(addedElements?.length > 0 || removedElements?.length > 0);
        }

        // Handle array of objects
        else if (isArrayofObjects(currentValue) || isArrayofObjects(previousValue)) {
            const addedElements = currentValue.filter(currItem => {
                const isAdded = isArrayofObjects(previousValue) ? !previousValue.some(prevItem => prevItem._id === currItem._id) : false;
                return isAdded;
            });
            const removedElements = previousValue?.length > 0 ? previousValue.filter(prevItem => {
                const isRemoved = !currentValue.some(currItem => currItem._id === prevItem._id);
                return isRemoved;
            }) : false;
            return !(addedElements?.length > 0 || removedElements?.length > 0);
        }

        // Handle objects and dates
        else if (typeof currentValue === 'object') {
            if (currentValue instanceof Date && !isNaN(currentValue)) {
                return JSON.stringify(currentValue) === JSON.stringify(previousValue);
            } else {
                const searchField = field?.searchField;
                const currentFieldValue = currentValue ? currentValue[searchField] : undefined;
                const previousFieldValue = previousValue ? previousValue[searchField] : undefined;
                return currentFieldValue === previousFieldValue;
            }
        } else {
            return JSON.stringify(currentValue) === JSON.stringify(previousValue);
        }
    },
}