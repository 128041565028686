import React, { useEffect, useState } from "react";
import { useForm, Controller } from 'react-hook-form';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Button } from "reactstrap";
import { Dialog } from 'primereact/dialog';
import fetchMethodRequest from '../../../config/service';
import showToasterMessage from '../../UI/ToasterMessage/toasterMessage';
import { Nav, NavItem, NavLink, TabContent, TabPane } from 'reactstrap';
import moment from 'moment';
import DoCKEditorField from "../../Form/Fields/DoEditor";
import DoSelectField from "../../Form/Fields/DoSelectField";
import DoMultiSelectField from "../../Form/Fields/DoMultiSelectField";
import * as Yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';

const AddNotes = (props) => {
    const [isOpenNotesModal, setIsOpenNotesModal] = useState(false);
    const [activeTab, setActiveTab] = useState('1');
    const [notesId, setNotesId] = useState('');
    const [isEditing, setIsEditing] = useState(false);
    const [projectNotesData, setProjectNotesData] = useState([]);
    const [isDisabled, setIsDisabled] = useState(false);
    const [isSubmitting, setIsSubmitting] = useState(false);

    const schema = Yup.object().shape({
        attendedEmployees: Yup.array()
        .transform((value) => (Array.isArray(value) ? value : []))
        .min(1, 'Please Select the Attended Employees')
        .required('Please Select the Attended Employees'),
    });

    let {
        handleSubmit,
        reset,
        setValue,
        getValues,
        formState: { errors },
        control,
    } = useForm({resolver: yupResolver(schema)});

    useEffect(() => {
        onGetMeetingNotes();    
        const todayNotes = props.item.meetingNotes.filter(note => {
            return moment(note.created).isSame(new Date(), 'day');
        });
        
        if (todayNotes.length > 0) {
            const note = todayNotes[0];
            setIsEditing(true);
            setNotesId(note._id);
            setValue("meetingNote", note.projectNotes);
            setValue("attendedEmployees", note.attendedEmployees[0] === "" ? '' : note.attendedEmployees);
            setValue("status", note.status);
            setIsDisabled(true)
        } else {
            reset({
                meetingNote: '',
                attendedEmployees: '',
                status: '',
            });
        }
    }, [props]);

    const notesField = [
        {
            "name": "meetingNote",
            "type": "ckeditor",
            "placeholder": "Meeting Notes",
            "label": "Meeting Notes",
            "width": "200px",
            "id": "meetingNote",
        },
        {
            "name": "attendedEmployees",
            "type": "multiSelect",
            "placeholder": "Select",
            "label": "Attended Employees",
            "width": 110,
            "id": "attendedEmployees",
            "options": [],
            "required": true
        },
        {
            "name": "status",
            "type": "dropdown",
            "placeholder": "Status",
            "label": "Status",
            "width": 110,
            "id": "status",
            "options": [
                { label: "In Discussion", value: "In Discussion" },
                { label: "Completed", value: "Completed" },
            ],
        },
    ]

    const updatedNotesField = notesField.map(field => {
        if (field.name === "attendedEmployees") {
            return {
                ...field,
                options: props.item.assignEmployees.map(employee => ({
                    label: employee.displayName,
                    value: employee
                }))
            };
        }
        return field;
    });

    const closeNotesModal = () => {
        setIsOpenNotesModal(false);
        setActiveTab('1');
        props.getDataFromServer(props.filterCriteria);
    }

    const toggleTab = (tab) => {
        if (activeTab !== tab) setActiveTab(tab);
        onGetMeetingNotes();
    };

    const onGetMeetingNotes = () =>{
        props.item.meetingNotes.sort((a, b) => {
            const dateA = new Date(a.meetingDate.split("-").reverse().join("-"));
            const dateB = new Date(b.meetingDate.split("-").reverse().join("-"));
            return dateB - dateA;
        });
        
        setProjectNotesData(props.item.meetingNotes)
        // let url = `projectNotes?projectId=${props.item._id}&fromMeetingNotes=true`
        // fetchMethodRequest('GET', url)
        //     .then(async (response) => {
        //         if (response) {
        //             setProjectNotesData(response.projectnotes)
        //         } else if (response && response.errorMessage) {
        //             showToasterMessage(response.errorMessage, 'error');
        //         }
        //     }).catch((err) => {
        //         return err;
        //     });
    }


    const onClickSaveButton = (formData) => {
        if (isSubmitting) {
            return;
        }
        setIsSubmitting(true);

        const payload = {
            "projectId": {
                "_id": props.item._id
            },
            "projectName": props.item.name,
            "projectNotes": formData.meetingNote,
            "attendedEmployees": formData.attendedEmployees === "" ? [""] : formData.attendedEmployees,
            "status": formData.status,
            "assignEmployees": props.item.assignEmployees
        }

        let method = isEditing ? 'PUT' : 'POST';
        let url = isEditing ? `projectNotes/${notesId}` : 'projectNotes';
        if (payload) {
            return fetchMethodRequest(method, url, payload)
                .then(async (response) => {
                    if (response && response.respCode && (response.respCode === 204 || response.respCode === 205)) {
                        showToasterMessage(response.respMessage, 'success');
                        reset({
                            meetingNote: '',
                            attendedEmployees: '',
                            status: '',
                        });
                        setActiveTab("2");
                        props.getDataFromServer(props.filterCriteria);
                        setIsDisabled(true)
                    } else if (response && response.errorMessage) {
                        showToasterMessage(response.errorMessage, 'error');
                    }
                    setIsSubmitting(false);
                }).catch((err) => {
                    return err;
                });

        } else {
            return;
        }

    }

    const onClickEdit = (notes) => {
        toggleTab('1')
        setIsEditing(true);
        setNotesId(notes._id)
        setValue("meetingNote", notes.projectNotes);
        setValue("attendedEmployees", notes.attendedEmployees[0] === "" ? '' : notes.attendedEmployees);
        setValue("status", notes.status);
    }

    const onClickTasks = (notes) => {

        const updatedNotes = {
            projectNotesId: notes._id,
            projectId: props.item._id
        };

        const encodedNotes = encodeURIComponent(JSON.stringify(updatedNotes));
        window.open(`/tasks?notes=${encodedNotes}`, '_blank');
    };


    function formatDate(dateStr) {
        if (!dateStr) return '';
        return moment(dateStr).format('DD-MM-YYYY hh:mm a');
    }

    let getNotesModal = () => {
        return (
            <Dialog header="Notes" visible={isOpenNotesModal} maximizable style={{ width: '50vw' }} onHide={closeNotesModal}>
                <Nav tabs>
                    <NavItem>
                        <NavLink className={`${activeTab === '1' ? "active-nav-class" : ""} ${isDisabled ? "nav-disabled" : ""}`} onClick={() => { toggleTab('1'); }}>
                            Notes
                        </NavLink>
                    </NavItem>
                    <NavItem>
                        <NavLink className={activeTab === '2' ? "active-nav-class" : ""} onClick={() => { toggleTab('2'); }}>
                            View Notes
                        </NavLink>
                    </NavItem>
                </Nav>
                <TabContent activeTab={activeTab}>
                    <TabPane tabId="1">
                        <form onSubmit={handleSubmit(onClickSaveButton)} className="mt-3">
                            <div className='row'>
                                {updatedNotesField && updatedNotesField.length > 0 && updatedNotesField.map((item, index) => (
                                    <div
                                        className={`pb-2 ${item.type === 'ckeditor' ? 'col-sm-12 ckeditor-notes-container' : 'col-sm-6'}`}
                                        key={item.name}
                                    >
                                        <Controller
                                            name={item.name}
                                            control={control}
                                            render={({ field, fieldState }) => (
                                                <>
                                                    {item.type === 'ckeditor' ? (
                                                        <DoCKEditorField
                                                            field={field}
                                                            errors={errors}
                                                            label={item.label}
                                                        />
                                                    ) : item.type === 'multiSelect' ? (
                                                        <DoMultiSelectField
                                                            markReq={item.required}
                                                            input={field}
                                                            id={field.id}
                                                            name={field.name}
                                                            field={field}
                                                            fieldState={fieldState}
                                                            errors={errors}
                                                            label={item.label}
                                                            options={item.options}
                                                            optionLabel={item.optionLabel}
                                                            placeholder={item.placeholder}
                                                            filter={true}
                                                        />
                                                    ) : item.type === 'dropdown' ? (
                                                        <DoSelectField
                                                            options={item.options}
                                                            input={field}
                                                            id={field.id}
                                                            name={field.name}
                                                            field={field}
                                                            label={item.label}
                                                            fieldState={fieldState}
                                                            item={item}
                                                            placeholder={item.placeholder}
                                                        />
                                                    ) : null}
                                                </>
                                            )}
                                        />
                                    </div>
                                ))}
                            </div>

                            <div style={{ display: 'flex', justifyContent: 'center', marginTop: '10px' }}>
                                <Button type="button" color="primary" onClick={closeNotesModal} style={{ marginRight: '20px' }}>
                                    Close
                                </Button>
                                <Button type='submit' color="primary" disabled={isSubmitting}>
                                    Save
                                </Button>
                            </div>
                        </form>
                    </TabPane>
                    <TabPane tabId="2">
                        <div className="notes-history mt-3">
                            {projectNotesData.length > 0 ? (
                                projectNotesData.map((note, index) => (
                                    <div key={index} className="notes-card mb-3">
                                        <span className="ms-3">{note.meetingDate}</span>
                                        <div className="card-body d-flex justify-content-between">
                                            <div className="me-5 flex-grow-1">
                                                <h6 className="card-ti" dangerouslySetInnerHTML={{ __html: note.projectNotes }} />
                                                <div className="mt-3">
                                                    <small className="row text-muted show-employees">
                                                        <span className="col-4">
                                                            <b>Attended Employees:</b>
                                                        </span>
                                                        <span className="col-8">
                                                            {note.attendedEmployees && note.attendedEmployees.length > 0 ?
                                                                note.attendedEmployees.map(item => item.displayName).join(', ')
                                                                : 'None'}
                                                        </span>
                                                        <span className="col-4">
                                                            <b>Task Id's:</b>
                                                        </span>
                                                        <span className="col-8 text-success">
                                                            {note.taskIDs && note.taskIDs.length > 0 ?
                                                                note.taskIDs.join(', ') 
                                                                : 'None'}
                                                        </span>
                                                    </small>
                                                </div>
                                            </div>
                                            <div className="d-flex flex-column align-items-end">
                                                <div style={{ display: 'flex', justifyContent: 'flex-end', marginBottom: '10px' }}>
                                                    <FontAwesomeIcon icon='edit' style={{ color: '#0000FF', marginLeft: '10px', cursor: 'pointer' }} data-toggle="tool-tip" onClick={() => onClickEdit(note)} title="Edit" />
                                                    <FontAwesomeIcon icon='tasks' style={{ color: 'coral', marginLeft: '10px', cursor: 'pointer' }} data-toggle="tool-tip" onClick={() => onClickTasks(note)} title="Create Task" />
                                                </div>
                                                <div className="date-time">
                                                    <small className="text-muted"><b>Created:</b> {formatDate(note.created)}</small>
                                                </div>
                                                <div className="date-time">
                                                    <small className="text-muted">
                                                        <b>Status: </b> 
                                                        <span style={{ color: note.status === "In Discussion" ? "coral" : "#0000FF" }}>
                                                            {note.status ? note.status : "None"}
                                                        </span>                                                   
                                                    </small>                                                
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                ))
                            ) : (
                                <div className="no-notes">
                                    <p>No Notes Availabale</p>
                                </div>
                            )}
                        </div>
                    </TabPane>
                </TabContent>
            </Dialog >
        )
    }

    return (
        <>
            <span>
                <FontAwesomeIcon
                    className='genderIconAlignment'
                    color='white'
                    icon='sticky-note'
                    data-toggle="tool-tip"
                    title="Add Note"
                    style={{ color: 'rgb(128, 202, 255)', width: '15', marginLeft: 10, cursor: 'pointer' }}
                    onClick={() => {
                        setIsOpenNotesModal(true);
                        setActiveTab('1');
                    }}
                />
            </span>
            {isOpenNotesModal ? getNotesModal() : null}
        </>
    );
}

export default AddNotes;
