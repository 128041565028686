const apiCalls = {
  loginUser: "auth/login",
  logoutUser: "auth/logout",
  loginChangePassword: "auth/loginChangePassword",
  MenuList: "menus",
  Settings: "settings",
  LocationImagePath: "uploads?uploadWhileCreate=true&uploadPath=location",
  ProfileImagePath: "uploads?uploadWhileCreate=true&uploadPath=employee",
  dashboard: "dashboard/counts",
  Uploads: "bulkuploadStatus",
  employees: "employees",
  projects: "projects",
  tasks: "tasks",
  timeSheets: "timeSheets",
  leaves: "leaves",
  issues: "issues",
  dailyStatuses: "dailyStatuses",
  dashboards: "dashboards",
  attendences: "attendences",
  leaveHistories: "leaveHistories",
  publicHolidays: "publicHolidays",
  inventories: "inventories",
  inventoryHistories: "inventoryHistories",
  appraisals: "appraisals",
  presentations: "presentations",
  clients: "clients",
  setups: "setups",
  sprints: "sprints",
  events: "events",
  releases: "releases",
  backlogs: 'backlog'
};

// {"loginUser":"auth/login","logoutUser":"auth/logout","loginChangePassword":"auth/loginChangePassword","MenuList":"menus","Settings":"settings","LocationImagePath":"uploads?uploadWhileCreate=true&uploadPath=location","ProfileImagePath":"uploads?uploadWhileCreate=true&uploadPath=employee","dashboard":"dashboard/counts","employee":"employees","employees":"employees","Uploads":"bulkuploadStatus"}

export default apiCalls;
