import React from "react";
import apiCalls from "../../../../config/apiCalls";
import store from "../../../App/store";
import filePath from "../../../../config/configSampleFiles";
import DataTables from "../../CommonDataTable/DataTable";
import config from "../../../../config/config";

// config file
const EmailStatus = () => {

  const getDerivedStateFromProps = (props, state) => {
    let storeData = store.getState();
    let languageData =
      storeData &&
        storeData.settingsData &&
        storeData.settingsData.settings &&
        storeData.settingsData.settings.languageTranslation
        ? storeData.settingsData.settings.languageTranslation
        : "";
    return { languageData: languageData };
  }

  const getTableFields = () => {
    let data = [
      {
        textAlign: "center",
        width: 47,
        field: "Sno",
        header: "Sno",
        label: "Sno",
        filter: false,
        sortable: false,
        placeholder: "Search",
        show: true,
      },
      {
        show: true,
        textAlign: "left",
        width: 180,
        mobile: true,
        field: "to",
        header: "To",
        label: "TO",
        filter: false,
        sortable: true,
        displayInSettings: true,
      },
      {
        show: true,
        textAlign: "left",
        width: 160,
        mobile: true,
        field: "from",
        header: "From",
        label: "From",
        filter: false,
        sortable: true,
        displayInSettings: true,
      },
      {
        show: true,
        textAlign: "left",
        width: 160,
        mobile: true,
        field: "bcc",
        label: "Bcc",
        header: "Bcc",
        filter: false,
        sortable: true,
        displayInSettings: true,
      },
      {
        show: true,
        textAlign: "left",
        width: 140,
        field: "subject",
        mobile: true,
        header: "Subject",
        label: "Subject",
        filter: false,
        sortable: true,
        displayInSettings: true,
      },
      {
        show: true,
        textAlign: "left",
        width: 180,
        field: "html",
        mobile: true,
        header: "Html",
        label: "Html",
        filter: false,
        sortable: true,
        displayInSettings: true,
      },
      {
        show: true,
        textAlign: "left",
        width: 180,
        field: "templateName",
        mobile: true,
        label: "Template Name",
        header: "Template Name",
        filter: false,
        sortable: true,
        displayInSettings: true,
      },
      {
        show: true,
        textAlign: "left",
        width: 100,
        field: "status",
        mobile: true,
        header: "Status",
        label: "Status",
        fieldType: "Badge",
        style: {
          padding: "4px 6px",
          fontSize: 12,
          color: "white",
          textTransform: "capitalize",
          fontWeight: "bold",
        },
        options: [
          {
            label: "Sent",
            value: "Sent",
            color: "success",
          },
          {
            label: "Pending",
            value: "Pending",
            color: "danger",
          },
          {
            label: "Failed",
            value: "Failed",
            color: "danger",
          },
        ],
        filter: false,
        sortable: true,
      },
      {
        show: true,
        textAlign: "left",
        width: 160,
        field: "reason",
        header: "Reason",
        label: "Reason",
        filter: false,
        sortable: true,
        displayInSettings: true,
      },
      {
        show: true,
        textAlign: "left",
        width: 180,
        field: "created",
        header: "Created",
        label: "Created",
        fieldType: "Date",
        dateFormat: config.fullDateFormat,
        filter: false,
        sortable: true,
        displayInSettings: true,
      },
      // {"name":"to","fieldName":"to","type":"text","placeholder":"To","value":"","label":"To","width":80,"addFormOrder":1,"editFormOrder":1,"header":"To","derivedValue":"to=to=undefined","capitalizeTableText":false,"sortable":true,"filter":true,"id":"to","displayinaddForm":"false","displayineditForm":"false","displayinregisterForm":false,"displayinlist":"true","isFieldRequired":"false","required":false,"displayOptionsInActions":"","globalSearchField":"true","controllerId":null,"isClickable":"","isAddToIndex":false,"isBulkUploadField":true,"textAlign":"center","show":true,"field":"to","showOrHideFields":[],"mobile":true,"displayInSettings":true,"fieldType":"Link","style":{"color":"#f17038","cursor":"pointer","textTransform":"capitalize"},"tDisplay":true},
      // {"name":"from","fieldName":"from","type":"text","placeholder":"From","value":"","label":"From","width":80,"addFormOrder":2,"editFormOrder":2,"header":"From","derivedValue":"from=from=undefined","capitalizeTableText":false,"sortable":true,"filter":true,"id":"from","displayinaddForm":"false","displayineditForm":"false","displayinregisterForm":false,"displayinlist":"true","isFieldRequired":"false","required":false,"displayOptionsInActions":"","globalSearchField":"true","controllerId":null,"isClickable":"","isAddToIndex":false,"isBulkUploadField":true,"textAlign":"center","show":true,"field":"from","showOrHideFields":[],"mobile":true,"displayInSettings":true,"tDisplay":true},
      // {"name":"bcc","fieldName":"bcc","type":"text","placeholder":"Bcc","value":"","label":"Bcc","width":80,"addFormOrder":3,"editFormOrder":3,"header":"Bcc","derivedValue":"bcc=undefined","capitalizeTableText":false,"sortable":true,"filter":true,"id":"bcc","displayinaddForm":"false","displayineditForm":"false","displayinregisterForm":false,"displayinlist":"true","isFieldRequired":"false","required":false,"displayOptionsInActions":"","globalSearchField":"true","controllerId":null,"isClickable":"","isAddToIndex":false,"isBulkUploadField":true,"textAlign":"center","show":true,"field":"bcc","showOrHideFields":[],"mobile":true,"displayInSettings":true,"tDisplay":true},{"name":"subject","fieldName":"subject","type":"text","placeholder":"Subject","value":"","label":"Subject","width":80,"addFormOrder":4,"editFormOrder":4,"header":"Subject","derivedValue":"subject=undefined","capitalizeTableText":false,"sortable":true,"filter":true,"id":"subject","displayinaddForm":"false","displayineditForm":"false","displayinregisterForm":false,"displayinlist":"true","isFieldRequired":"false","required":false,"displayOptionsInActions":"","globalSearchField":"true","controllerId":null,"isClickable":"","isAddToIndex":false,"isBulkUploadField":true,"textAlign":"center","show":true,"field":"subject","showOrHideFields":[],"mobile":true,"displayInSettings":true,"tDisplay":true},
      // {"name":"html","fieldName":"html","type":"text","placeholder":"Html","value":"","label":"Html","width":80,"addFormOrder":5,"editFormOrder":5,"header":"Html","derivedValue":"html=undefined","capitalizeTableText":false,"sortable":true,"filter":true,"id":"html","displayinaddForm":"false","displayineditForm":"false","displayinregisterForm":false,"displayinlist":"true","isFieldRequired":"false","required":false,"displayOptionsInActions":"","globalSearchField":"true","controllerId":null,"isClickable":"","isAddToIndex":false,"isBulkUploadField":true,"textAlign":"center","show":true,"field":"html","showOrHideFields":[],"mobile":true,"displayInSettings":true,"tDisplay":true},
      // {"name":"templateName","fieldName":"templateName","type":"text","placeholder":"Template Name","value":"","label":"Template Name","width":80,"addFormOrder":6,"editFormOrder":6,"header":"Template Name","derivedValue":"templateName=undefined","capitalizeTableText":false,"sortable":true,"filter":true,"id":"templateName","displayinaddForm":"false","displayineditForm":"false","displayinregisterForm":false,"displayinlist":"true","isFieldRequired":"false","required":false,"displayOptionsInActions":"","globalSearchField":"true","controllerId":null,"isClickable":"","isAddToIndex":false,"isBulkUploadField":true,"textAlign":"center","show":true,"field":"templateName","showOrHideFields":[],"mobile":true,"displayInSettings":true,"tDisplay":true},
      // {"name":"status","fieldName":"status","type":"text","placeholder":"Status","value":"","label":"Status","width":120,"addFormOrder":7,"editFormOrder":7,"header":"Status","derivedValue":"status=undefined","capitalizeTableText":false,"sortable":true,"filter":true,"id":"status","displayinaddForm":"false","displayineditForm":"false","displayinregisterForm":false,"displayinlist":"true","isFieldRequired":"false","required":false,"displayOptionsInActions":"","globalSearchField":"true","controllerId":null,"isClickable":"","isAddToIndex":false,"isBulkUploadField":true,"textAlign":"center","show":true,"field":"status","showOrHideFields":[],"mobile":true,"displayInSettings":true,"tDisplay":true},
      // {"name":"reason","fieldName":"reason","type":"text","placeholder":"Reason","value":"","label":"Reason","width":80,"addFormOrder":8,"editFormOrder":8,"header":"Reason","derivedValue":"reason=undefined","capitalizeTableText":false,"sortable":true,"filter":true,"id":"reason","displayinaddForm":"false","displayineditForm":"false","displayinregisterForm":false,"displayinlist":"true","isFieldRequired":"false","required":false,"displayOptionsInActions":"","globalSearchField":"true","controllerId":null,"isClickable":"","isAddToIndex":false,"isBulkUploadField":true,"textAlign":"center","show":true,"field":"reason","showOrHideFields":[],"mobile":true,"displayInSettings":true,"tDisplay":true},
      // {"name":"created","fieldName":"created","type":"date","placeholder":"Created","value":"","label":"Created","width":90,"addFormOrder":9,"editFormOrder":9,"header":"Created","derivedValue":"created=created=undefined","capitalizeTableText":"","sortable":"","filter":true,"actions":[],"actionsNumber":[],"id":"created","displayinaddForm":"false","displayineditForm":"false","displayinregisterForm":"","displayinlist":"true","isFieldRequired":"false","required":false,"displayOptionsInActions":"","globalSearchField":"true","controllerId":null,"isClickable":"","isAddToIndex":false,"isBulkUploadField":true,"dateFormat":"YYYY-MM-DD HH:mm:ss","fieldType":"Date","textAlign":"center","show":true,"field":"created","showOrHideFields":[],"mobile":true,"displayInSettings":true,"tDisplay":true},
    ];
    return data;
  };

  const getFormFields = () => {
    return [];
  }
  
    return (
      <DataTables
        getTableFields={getTableFields}
        formFields={getFormFields}
        // viewRequired={true}
        // settingsRequired={true}
        globalSearch={"To/From/Template Name"}
        type="Email Statuses"
        displayName="Email Statuses"
        apiUrl={"emailStatus"}
        exportRequried={false}
        printRequried={false}
        addRequried={false}
        editRequired={false}
        deleteRequired={false}
        viewRequired={false}
        settingsRequired={true}
        filterRequired={false}
        gridRequried={false}
        sample={false}
        globalSearchFieldName='activity'
        apiResponseKey={"emailstatus"}
        routeTo='emailStatus'
        displayViewOfForm='screen'
         
         
         
      />
    );
  }
export default EmailStatus;
