import React, { useState } from 'react';
import { Button, Modal, ModalBody, ModalHeader } from 'reactstrap';

const PreviewModal = ({type, emailTemplateData,closePreviewModal,isPreviewModal}) => {

    

        return (
            <div style={{ display: 'flex' }}>
                <Modal isOpen={isPreviewModal}
                    className={` modal-dialog--primary modal-dialog--header `}
                >
                    <ModalHeader className="modal__header viewModalHeader" >
                        Email Preview
                        <button className="lnr lnr-cross modal__close-btn" type="button"
                            onClick={closePreviewModal} />
                    </ModalHeader>
                    <ModalBody id='templateText'>
                        <div style={{ fontFamily: 'Arial, sans-serif', color: '#333', lineHeight: '1.6' }}>
                            <h3 style={{ color: '#2c3e50', marginBottom: '20px' }}>{emailTemplateData.subject}</h3>
                            <span dangerouslySetInnerHTML={{ __html: emailTemplateData.templateText }} ></span>
                        </div>
                    </ModalBody>
                </Modal>
            </div>
        );
    
}


export default PreviewModal;