import React from 'react';
import { Button, Modal, ModalBody } from 'reactstrap';

const AutoLeavesApproveModal = ({ openConfirmationModal, onConfirm, onClose }) => {
    return (
        <Modal isOpen={openConfirmationModal} className='modal-dialog-centered'>
            <ModalBody className='deleteModalBody'>
                <div style={{ marginBottom: 10 }}>
                    Are you sure you want to enable the auto-approve timesheet feature for this Employee?
                </div>
                <Button color="primary" outline onClick={onClose} className='deleteModalBtn marginRight'>No</Button>
                <Button color="primary" outline onClick={onConfirm} className='deleteModalBtn'>Yes</Button>
            </ModalBody>
        </Modal>
    );
};

export default AutoLeavesApproveModal;
