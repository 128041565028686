import React, { useEffect, useState, useRef } from "react";
import DataTables from "../../CommonDataTable/DataTable";
import config from "../../../../config/config";
import apiCalls from "../../../../config/apiCalls";
import ViewModal from "../../CommonModals/viewModal";
import NewUserModal from "../../CommonModals/NewUserModal";
import showToasterMessage from "../../../UI/ToasterMessage/toasterMessage";
import FormModal from "../../../Form/FormModal";
import store from "../../../App/store";
import filePath from "../../../../config/configSampleFiles";
import fetchMethodRequest from "../../../../config/service";
import RolePermissions from "../../CommonModals/Permissions";
import { useParams } from "react-router";
// config file

const Sprints = (props) => {
  const [languageData, setLanguageData] = useState();
  const [rolePermission, setRolePermission] = useState();
  const [editSelectedRecord, setEditSelectedRecord] = useState(
    window.location.href.includes("edit")
  );
  const [addSelectedRecord, setAddSelectedRecord] = useState(
    window.location.href.includes("create")
  );
  const [viewSelectedRecord, setViewSelectedRecord] = useState(
    window.location.href.includes("view")
  );
  const [isOpenFormModal, setIsOpenFormModal] = useState(false);
  const [openNewUserModal, setopenNewUserModal] = useState(false);
  const [item, setItem] = useState({});
  const [newFormFields, setNewFormField] = useState([]);

  let dataTableRef = useRef(null);
  const params = useParams();
  useEffect(() => {
    let screenPermissions = RolePermissions.screenPermissions("Sprints");
    if (screenPermissions) {
      setRolePermission(screenPermissions);
    }
  }, []);

  const getDerivedStateFromProps = (props, state) => {
    let storeData = store.getState();
    let languageData =
      storeData &&
      storeData.settingsData &&
      storeData.settingsData.settings &&
      storeData.settingsData.settings.languageTranslation
        ? storeData.settingsData.settings.languageTranslation
        : "";
    return { languageData: languageData };
  };

  let loginRole = JSON.parse(localStorage.getItem('loginCredentials'))

  const setData = (props) => {
    let rowData = {};
    if (params.id) {
      rowData["_id"] = JSON.parse(params.id);
      return rowData;
    }
  };

  const getTableFields = () => {
    let roleTypes = [
      {
        label: "All",
        value: null,
      },
      {
        label: "Admin",
        value: "Admin",
      },
      {
        label: "Super Admin",
        value: "Super Admin",
      },
      {
        label: "User",
        value: "User",
      },
    ];
    let StatusTypes = [
      {
        label: "All",
        value: null,
      },
      {
        label: "Active",
        value: "Active",
      },
      {
        label: "Inactive",
        value: "Inactive",
      },
      {
        label: "Pending",
        value: "Pending",
      },
    ];
    let data = [
      {
        textAlign: "center",
        width: 47,
        field: "Check Box",
        label: "Check Box",
        fieldType: "multiple",
        header: "",
        selectionMode: "multiple",
        show: true,
        mobile: true,
        displayInSettings: true,
        displayinlist: "false",
        displayinaddForm: "false",
        displayineditForm: "false",
        globalSearchField: "false",
        isFieldRequired: "false",
        tDisplay: true,
      },
      {
        textAlign: "center",
        width: 47,
        field: "Sno",
        label: "SNo",
        header: "S.No",
        filter: false,
        sortable: false,
        placeholder: "Search",
        displayinlist: "false",
        displayinaddForm: "false",
        displayineditForm: "false",
        globalSearchField: "false",
        isFieldRequired: "false",
        show: true,
        mobile: true,
        displayInSettings: true,
        tDisplay: true,
      },
      {
        name: "sprintName",
        fieldName: "sprintName",
        type: "text",
        placeholder: "Sprint Name",
        value: "",
        label: "Sprint Name",
        width: 110,
        addFormOrder: 1,
        editFormOrder: 1,
        header: "Sprint Name",
        derivedValue:
          "name=name=sprintName=sprintName=name=sprintName=undefined",
        capitalizeTableText: true,
        sortable: true,
        filter: true,
        id: "name",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinregisterForm: false,
        displayinlist: "true",
        isFieldRequired: "false",
        required: false,
        displayOptionsInActions: "",
        globalSearchField: "true",
        controllerId: null,
        isClickable: true,
        isAddToIndex: false,
        isBulkUploadField: true,
        textAlign: "center",
        show: true,
        field: "name",
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
        fieldType: "Link",
        style: {
          color: "#f17038",
          cursor: "pointer",
          textTransform: "capitalize",
        },
        tDisplay: true,
      },
      {
        name: "createdByName",
        fieldName: "createdByName",
        type: "text",
        placeholder: "Created By",
        value: "",
        label: "Created By",
        width: "120px",
        addFormOrder: 10,
        editFormOrder: 10,
        header: "Created By",
        derivedValue: "createdByName=createdBy=undefined",
        capitalizeTableText: true,
        sortable: true,
        filter: true,
        id: "createdByName",
        displayinaddForm: "false",
        displayineditForm: "false",
        displayinregisterForm: false,
        displayinlist: "true",
        isFieldRequired: "false",
        required: false,
        displayOptionsInActions: "",
        globalSearchField: "false",
        controllerId: "employee",
        isClickable: "",
        fieldType: "Text",
        isAddToIndex: false,
        isBulkUploadField: false,
        textAlign: "center",
        show: true,
        field: "createdByName",
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
        tDisplay: true,
      },
      {
        name: "updatedByName",
        fieldName: "updatedByName",
        type: "text",
        placeholder: "Updated By",
        value: "",
        label: "Updated By",
        width: "120px",
        addFormOrder: 10,
        editFormOrder: 10,
        header: "Updated By",
        derivedValue: "updatedByName=updatedBy=undefined",
        capitalizeTableText: true,
        sortable: true,
        filter: true,
        id: "updatedByName",
        displayinaddForm: "false",
        displayineditForm: "false",
        displayinregisterForm: false,
        displayinlist: "true",
        isFieldRequired: "false",
        required: false,
        displayOptionsInActions: "",
        globalSearchField: "false",
        controllerId: "employee",
        isClickable: "",
        fieldType: "Text",
        isAddToIndex: false,
        isBulkUploadField: false,
        textAlign: "center",
        show: true,
        field: "updatedByName",
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
        tDisplay: true,
      },
      {
        name: "created",
        fieldName: "created",
        type: "date",
        placeholder: "Created",
        value: "",
        label: "Created",
        width: 90,
        addFormOrder: 10,
        editFormOrder: 10,
        header: "Created",
        derivedValue: "created=created=created=undefined",
        capitalizeTableText: "",
        sortable: true,
        filter: true,
        actions: [],
        actionsNumber: [],
        id: "created",
        displayinaddForm: "false",
        displayineditForm: "false",
        displayinregisterForm: "",
        displayinlist: "true",
        isFieldRequired: "false",
        required: false,
        displayOptionsInActions: "",
        globalSearchField: "false",
        controllerId: null,
        isClickable: "",
        isAddToIndex: false,
        isBulkUploadField: true,
        dateFormat: "DD-MM-YYYY",
        fieldType: "Date",
        textAlign: "center",
        disabled: true,
        show: true,
        field: "created",
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
        tDisplay: true,
      },
      {
        name: "updated",
        fieldName: "updated",
        type: "date",
        placeholder: "Updated",
        value: "",
        label: "Updated",
        width: 90,
        addFormOrder: 10,
        editFormOrder: 10,
        header: "Updated",
        derivedValue: "updated=updated=updated=undefined",
        capitalizeTableText: "",
        sortable: true,
        filter: true,
        actions: [],
        actionsNumber: [],
        id: "updated",
        displayinaddForm: "false",
        displayineditForm: "false",
        displayinregisterForm: "",
        displayinlist: "true",
        isFieldRequired: "false",
        required: false,
        displayOptionsInActions: "",
        globalSearchField: "false",
        controllerId: null,
        isClickable: "",
        isAddToIndex: false,
        isBulkUploadField: true,
        dateFormat: "DD-MM-YYYY",
        fieldType: "Date",
        textAlign: "center",
        disabled: true,
        show: true,
        field: "updated",
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
        tDisplay: true,
      },
      {
        name: "projectId",
        fieldName: "projectId",
        type: "relateAutoComplete",
        placeholder: "ProjectId",
        value:"",
        label: "ProjectId",
        width: "150px",
        addFormOrder: 7,
        editFormOrder: 7,
        header: "Project Name",
        derivedValue:
          "projectId=projectId=projectId=projectId=projectId=projectId=undefined",
        capitalizeTableText: false,
        sortable: true,
        filter: true,
        actions: [],
        actionsNumber: [],
        id: "projectId",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinregisterForm: "",
        displayinlist: "true",
        isFieldRequired: "false",
        required: false,
        displayOptionsInActions: false,
        globalSearchField: "false",
        controllerId: 1007,
        isClickable: false,
        isAddToIndex: "",
        isBulkUploadField: false,
        searchField: "name",
        fieldType: "relateAutoComplete",
        populteFields: [],
        displayFields: ["name"],
        controllerName: "Project",
        searchApi: "projects",
        isMultiple: false,
        textAlign: "center",
        show: true,
        field: "projectId",
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
        tDisplay: true,
      },
      {
        name: "startDate",
        fieldName: "startDate",
        type: "date",
        placeholder: "Start Date",
        value: "",
        label: "Start Date",
        width: "130px",
        addFormOrder: 8,
        editFormOrder: 8,
        header: "Start Date",
        derivedValue: "startDate=undefined",
        capitalizeTableText: false,
        sortable: true,
        filter: true,
        actions: [],
        actionsNumber: [],
        id: "startDate",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinregisterForm: "",
        displayinlist: "true",
        isFieldRequired: "false",
        required: false,
        displayOptionsInActions: false,
        globalSearchField: "false",
        controllerId: null,
        isClickable: false,
        isAddToIndex: false,
        isBulkUploadField: true,
        dateFormat: "DD-MM-YYYY",
        fieldType: "Date",
        textAlign: "center",
        show: true,
        field: "startDate",
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
        tDisplay: true,
      },
      {
        name: "endDate",
        fieldName: "endDate",
        type: "date",
        placeholder: "End Date",
        value: "",
        label: "End Date",
        width: "130px",
        addFormOrder: 5,
        editFormOrder: 5,
        header: "End Date",
        derivedValue: "endDate=endDate=endDate=endDate=endDate=undefined",
        capitalizeTableText: false,
        sortable: true,
        filter: true,
        actions: [],
        actionsNumber: [],
        id: "endDate",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinregisterForm: "",
        displayinlist: "true",
        isFieldRequired: "false",
        required: false,
        displayOptionsInActions: false,
        globalSearchField: "false",
        controllerId: null,
        isClickable: false,
        isAddToIndex: false,
        isBulkUploadField: true,
        dateFormat: "DD-MM-YYYY",
        fieldType: "Date",
        textAlign: "center",
        show: true,
        field: "endDate",
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
        tDisplay: true,
      },
      {
        name: "status",
        fieldName: "status",
        type: "dropDown",
        placeholder: "Status",
        value: "",
        label: "Status",
        width: "130px",
        addFormOrder: 3,
        editFormOrder: 3,
        header: "Status",
        derivedValue:
          "status=status=status=status=status=status=status=undefined",
        capitalizeTableText: false,
        sortable: true,
        filter: true,
        actions: [],
        actionsNumber: [],
        id: "status",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinregisterForm: "",
        displayinlist: "true",
        isFieldRequired: "false",
        required: false,
        displayOptionsInActions: true,
        globalSearchField: "true",
        controllerId: null,
        isClickable: false,
        isAddToIndex: false,
        isBulkUploadField: true,
        options: [
          { label: "Not Started", value: "Not Started", color: "danger" },
          { label: "In Progress", value: "In Progress", color: "success" },
          { label: "Dev Completed", value: "Dev Completed", color: "primary" },
          { label: "Completed", value: "Completed", color: "secondary" },
        ],
        fieldType: "dropDown",
        filterElement: [
          { label: "Not Started", value: "Not Started" },
          { label: "In Progress", value: "In Progress" },
          { label: "Dev Completed", value: "Dev Completed" },
          { label: "Completed", value: "Completed" },
        ],
        // dependent: [],
        textAlign: "center",
        show: true,
        field: "status",
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
        tDisplay: true,
      },
      {
        show: loginRole?.roleType == "Employee" ? false : true,
        textAlign: "center",
        width: 60,
        fieldType: "Actions",
        field: "Actions",
        header: "Actions",
        label: "Actions",
        filter: false,
        sortable: false,
        displayInSettings: loginRole?.roleType == "Employee" ? false : true,
        displayinServer: "false",
        displayinlist: "true",
        displayinaddForm: "false",
        displayineditForm: "false",
        globalSearchField: "false",
        isFieldRequired: "false",
        mobile: true,
        tDisplay: true,
      },
      // {
      //   name: "tasks",
      //   type: "relateAutoComplete",
      //   placeholder: "Tasks",
      //   label: "Tasks",
      //   width: "150px",
      //   addFormOrder: 11,
      //   editFormOrder: 11,
      //   header: "Tasks",
      //   derivedValue: "tasks=undefined",
      //   capitalizeTableText: false,
      //   sortable: false,
      //   isClickable: false,
      //   filter: false,
      //   actions: [],
      //   actionsNumber: [],
      //   id: "tasks",
      //   displayinaddForm: "true",
      //   displayineditForm: "true",
      //   displayinlist: "true",
      //   isFieldRequired: "false",
      //   required: false,
      //   displayOptionsInActions: false,
      //   globalSearchField: "false",
      //   controllerId: 1008,
      //   searchField: "taskSummary",
      //   fieldType: "relateAutoComplete",
      //   populteFields: [],
      //   displayFields: [],
      //   controllerName: "Task",
      //   searchApi: "tasks",
      //   isMultiple: true,
      //   textAlign: "Center",
      //   show: true,
      //   field: "tasks",
      //   showOrHideFields: [],
      //   mobile: true,
      //   displayInSettings: true,
      //   tDisplay: true,
      // },
      // {
      //   name: "assignedTo",
      //   type: "relateAutoComplete",
      //   placeholder: "AssignedTo",
      //   label: "AssignedTo",
      //   width: "150px",
      //   addFormOrder: 12,
      //   editFormOrder: 12,
      //   header: "AssignedTo",
      //   derivedValue: "assignedTo=undefined",
      //   capitalizeTableText: false,
      //   sortable: false,
      //   isClickable: false,
      //   filter: false,
      //   actions: [],
      //   actionsNumber: [],
      //   id: "assignedTo",
      //   displayinaddForm: "true",
      //   displayineditForm: "true",
      //   displayinlist: "true",
      //   isFieldRequired: "false",
      //   required: false,
      //   displayOptionsInActions: false,
      //   globalSearchField: "false",
      //   controllerId: 1001,
      //   searchField: "name",
      //   fieldType: "relateAutoComplete",
      //   populteFields: [],
      //   displayFields: ["name"],
      //   controllerName: "employee",
      //   searchApi: "employees",
      //   isMultiple: false,
      //   textAlign: "Center",
      //   show: true,
      //   field: "assignedTo",
      //   showOrHideFields: [],
      //   mobile: true,
      //   displayInSettings: true,
      //   tDisplay: true,
      // },
    ];
    return data;
  };

  const getFormFields = () => {
    let statusTypes = [
      {
        label: "Active",
        value: "Active",
      },
      {
        label: "Inactive",
        value: "Inactive",
      },
      {
        label: "Pending",
        value: "Pending",
      },
    ];
    return [
      {
        name: "name",
        fieldName: "name",
        type: "text",
        placeholder: "Sprint Name",
        value: "",
        label: "Sprint Name",
        width: 110,
        addFormOrder: 1,
        editFormOrder: 1,
        derivedValue:
          "name=name=sprintName=sprintName=name=sprintName=undefined",
        capitalizeTableText: true,
        sortable: true,
        filter: true,
        id: "sprintName",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinregisterForm: false,
        displayinlist: "true",
        isFieldRequired: true,
        required: true,
        displayOptionsInActions: "",
        globalSearchField: "true",
        controllerId: null,
        isClickable: true,
        isAddToIndex: false,
        isBulkUploadField: true,
        show: true,
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
        fieldType: "Link",
        style: {
          color: "#f17038",
          cursor: "pointer",
          textTransform: "capitalize",
        },
        isAddFormHidden: false,
        isEditFormHidden: false,
      },
      {
        name: "projectId",
        fieldName: "projectId",
        type: "relateAutoComplete",
        placeholder: "Project Name",
        value: "",
        label: "Project Name",
        width: "150px",
        addFormOrder: 2,
        editFormOrder: 2,
        derivedValue:
          "projectId=projectId=projectId=projectId=projectId=projectId=undefined",
        capitalizeTableText: false,
        sortable: false,
        filter: false,
        actions: [],
        actionsNumber: [],
        id: "projectId",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinregisterForm: "",
        displayinlist: "true",
        isFieldRequired: "false",
        required: true,
        displayOptionsInActions: false,
        globalSearchField: "false",
        controllerId: 1007,
        isClickable: false,
        isAddToIndex: "",
        isBulkUploadField: false,
        searchField: "name",
        fieldType: "relateAutoComplete",
        populteFields: [],
        displayFields: ["name"],
        controllerName: "Project",
        searchApi: "projects",
        isMultiple: false,
        show: true,
        showOrHideFields: [],
        mobile: true,
        isEditFieldDisable: true,
        displayInSettings: true,
        isAddFormHidden: false,
        isEditFormHidden: false,
      },
      {
        name: "startDate",
        fieldName: "startDate",
        type: "date",
        placeholder: "Start Date",
        value: "",
        label: "Start Date",
        width: "130px",
        addFormOrder: 4,
        editFormOrder: 4,
        derivedValue: "startDate=startDate=startDate=startDate=undefined",
        capitalizeTableText: false,
        sortable: false,
        filter: false,
        actions: [],
        actionsNumber: [],
        id: "startDate",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinregisterForm: "",
        displayinlist: "true",
        isFieldRequired: "false",
        required: false,
        displayOptionsInActions: false,
        globalSearchField: "false",
        controllerId: null,
        isClickable: false,
        isAddToIndex: false,
        isBulkUploadField: true,
        dateFormat: "DD-MM-YYYY",
        fieldType: "Date",
        show: true,
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
        isAddFormHidden: false,
        isEditFormHidden: false,
      },
      {
        name: "endDate",
        fieldName: "endDate",
        type: "date",
        placeholder: "End Date",
        value: "",
        label: "End Date",
        width: "130px",
        addFormOrder: 5,
        editFormOrder: 5,
        derivedValue: "endDate=endDate=endDate=endDate=endDate=undefined",
        capitalizeTableText: false,
        sortable: false,
        filter: false,
        actions: [],
        actionsNumber: [],
        id: "endDate",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinregisterForm: "",
        displayinlist: "true",
        isFieldRequired: "false",
        required: false,
        displayOptionsInActions: false,
        globalSearchField: "false",
        controllerId: null,
        isClickable: false,
        isAddToIndex: false,
        isBulkUploadField: true,
        dateFormat: "DD-MM-YYYY",
        fieldType: "Date",
        show: true,
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
        isAddFormHidden: false,
        isEditFormHidden: false,
      },
      {
        name: "status",
        fieldName: "status",
        type: "dropDown",
        placeholder: "Status",
        value: "Not Started",
        label: "Status",
        width: "130px",
        addFormOrder: 3,
        editFormOrder: 3,
        derivedValue:
          "status=status=status=status=status=status=status=undefined",
        capitalizeTableText: false,
        sortable: false,
        filter: true,
        actions: [],
        actionsNumber: [],
        id: "status",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinregisterForm: "",
        displayinlist: "true",
        isFieldRequired: "false",
        required: true,
        displayOptionsInActions: true,
        globalSearchField: "true",
        controllerId: null,
        isClickable: false,
        isAddToIndex: false,
        isBulkUploadField: true,
        options: [
          { label: "Not Started", value: "Not Started", color: "primary" },
          { label: "In Progress", value: "In Progress", color: "primary" },
          { label: "Dev Completed", value: "Dev Completed", color: "primary" },
        ],
        altOptions: [
          { label: "In Progress", value: "In Progress", color: "primary" },
          { label: "Dev Completed", value: "Dev Completed", color: "primary" },
          { label: "Completed", value: "Completed" },
        ],
        fieldType: "dropDown",
        filterElement: [
          { label: "Not Started", value: "Not Started", color: "primary" },
          { label: "In Progress", value: "In Progress", color: "primary" },
          { label: "Dev Completed", value: "Dev Completed", color: "primary" },
          { label: "Completed", value: "Completed" },
        ],
        // dependent: [],
        show: true,
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
        isAddFormHidden: false,
        isEditFormHidden: false,
      },
      // {
      //   name: "tasks",
      //   type: "relateAutoComplete",
      //   placeholder: "Tasks",
      //   label: "Tasks",
      //   width: "150px",
      //   addFormOrder: 11,
      //   editFormOrder: 11,
      //   derivedValue: "tasks=undefined",
      //   capitalizeTableText: false,
      //   sortable: false,
      //   isClickable: false,
      //   filter: false,
      //   actions: [],
      //   actionsNumber: [],
      //   id: "tasks",
      //   displayinaddForm: "true",
      //   displayineditForm: "true",
      //   displayinlist: "true",
      //   isFieldRequired: "false",
      //   required: false,
      //   displayOptionsInActions: false,
      //   globalSearchField: "false",
      //   controllerId: 1008,
      //   searchField: "taskSummary",
      //   fieldType: "relateAutoComplete",
      //   populteFields: [],
      //   displayFields: [],
      //   controllerName: "Task",
      //   searchApi: "tasks",
      //   isMultiple: true,
      //   show: true,
      //   showOrHideFields: [],
      //   mobile: true,
      //   displayInSettings: true,
      //   isAddFormHidden: true,
      //   isEditFormHidden: true,
      // },
      {
        name: "assignedTo",
        type: "relateAutoComplete",
        placeholder: "AssignedTo",
        label: "AssignedTo",
        width: "150px",
        addFormOrder: 12,
        editFormOrder: 12,
        derivedValue: "assignedTo=undefined",
        capitalizeTableText: false,
        sortable: false,
        isClickable: false,
        filter: false,
        actions: [],
        actionsNumber: [],
        id: "assignedTo",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinlist: "true",
        isFieldRequired: "false",
        required: false,
        displayOptionsInActions: false,
        globalSearchField: "false",
        controllerId: 1001,
        searchField: "name",
        fieldType: "relateAutoComplete",
        populteFields: [],
        displayFields: ["name"],
        controllerName: "employee",
        searchApi: "employees",
        isMultiple: false,
        show: true,
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
        isAddFormHidden: true,
        isEditFormHidden: true,
      },
    ];
  };

  const submit = async (item) => {
    setIsOpenFormModal(true);
    // await this.formModalRef.getRowData(item, 'edit');
    console.log("Submit Button in sode          ");
  };
  const getMobileTableFields = () => {
    let data = [
      {
        textAlign: "center",
        width: 47,
        field: "Check Box",
        label: "Check Box",
        fieldType: "multiple",
        header: "",
        selectionMode: "multiple",
        show: true,
        mobile: true,
        displayInSettings: true,
        displayinlist: "false",
        displayinaddForm: "false",
        displayineditForm: "false",
        globalSearchField: "false",
        isFieldRequired: "false",
        tDisplay: true,
      },
      {
        textAlign: "center",
        width: 47,
        field: "Sno",
        label: "SNo",
        header: "S.No",
        filter: false,
        sortable: false,
        placeholder: "Search",
        displayinlist: "false",
        displayinaddForm: "false",
        displayineditForm: "false",
        globalSearchField: "false",
        isFieldRequired: "false",
        show: true,
        mobile: true,
        displayInSettings: true,
        tDisplay: true,
      },
      {
        name: "sprintName",
        fieldName: "sprintName",
        type: "text",
        placeholder: "Sprint Name",
        value: "",
        label: "Sprint Name",
        width: 110,
        addFormOrder: 1,
        editFormOrder: 1,
        header: "Sprint Name",
        derivedValue:
          "name=name=sprintName=sprintName=name=sprintName=undefined",
        capitalizeTableText: true,
        sortable: true,
        filter: true,
        id: "name",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinregisterForm: false,
        displayinlist: "true",
        isFieldRequired: "false",
        required: false,
        displayOptionsInActions: "",
        globalSearchField: "true",
        controllerId: null,
        isClickable: true,
        isAddToIndex: false,
        isBulkUploadField: true,
        textAlign: "center",
        show: true,
        field: "name",
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
        fieldType: "Link",
        style: {
          color: "#f17038",
          cursor: "pointer",
          textTransform: "capitalize",
        },
        tDisplay: true,
      },
      {
        name: "createdByName",
        fieldName: "createdByName",
        type: "text",
        placeholder: "Created By",
        value: "",
        label: "Created By",
        width: "120px",
        addFormOrder: 10,
        editFormOrder: 10,
        header: "Created By",
        derivedValue: "createdByName=createdBy=undefined",
        capitalizeTableText: true,
        sortable: true,
        filter: true,
        id: "createdByName",
        displayinaddForm: "false",
        displayineditForm: "false",
        displayinregisterForm: false,
        displayinlist: "true",
        isFieldRequired: "false",
        required: false,
        displayOptionsInActions: "",
        globalSearchField: "false",
        controllerId: "employee",
        isClickable: "",
        fieldType: "Text",
        isAddToIndex: false,
        isBulkUploadField: false,
        textAlign: "center",
        show: true,
        field: "createdByName",
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
        tDisplay: true,
      },
      {
        name: "updatedByName",
        fieldName: "updatedByName",
        type: "text",
        placeholder: "Updated By",
        value: "",
        label: "Updated By",
        width: "120px",
        addFormOrder: 10,
        editFormOrder: 10,
        header: "Updated By",
        derivedValue: "updatedByName=updatedBy=undefined",
        capitalizeTableText: true,
        sortable: true,
        filter: true,
        id: "updatedByName",
        displayinaddForm: "false",
        displayineditForm: "false",
        displayinregisterForm: false,
        displayinlist: "true",
        isFieldRequired: "false",
        required: false,
        displayOptionsInActions: "",
        globalSearchField: "false",
        controllerId: "employee",
        isClickable: "",
        fieldType: "Text",
        isAddToIndex: false,
        isBulkUploadField: false,
        textAlign: "center",
        show: true,
        field: "updatedByName",
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
        tDisplay: true,
      },
      {
        name: "created",
        fieldName: "created",
        type: "date",
        placeholder: "Created",
        value: "",
        label: "Created",
        width: 90,
        addFormOrder: 10,
        editFormOrder: 10,
        header: "Created",
        derivedValue: "created=created=created=undefined",
        capitalizeTableText: "",
        sortable: true,
        filter: true,
        actions: [],
        actionsNumber: [],
        id: "created",
        displayinaddForm: "false",
        displayineditForm: "false",
        displayinregisterForm: "",
        displayinlist: "true",
        isFieldRequired: "false",
        required: false,
        displayOptionsInActions: "",
        globalSearchField: "false",
        controllerId: null,
        isClickable: "",
        isAddToIndex: false,
        isBulkUploadField: true,
        dateFormat: "DD-MM-YYYY",
        fieldType: "Date",
        textAlign: "center",
        disabled: true,
        show: true,
        field: "created",
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
        tDisplay: true,
      },
      {
        name: "updated",
        fieldName: "updated",
        type: "date",
        placeholder: "Updated",
        value: "",
        label: "Updated",
        width: 90,
        addFormOrder: 10,
        editFormOrder: 10,
        header: "Updated",
        derivedValue: "updated=updated=updated=undefined",
        capitalizeTableText: "",
        sortable: true,
        filter: true,
        actions: [],
        actionsNumber: [],
        id: "updated",
        displayinaddForm: "false",
        displayineditForm: "false",
        displayinregisterForm: "",
        displayinlist: "true",
        isFieldRequired: "false",
        required: false,
        displayOptionsInActions: "",
        globalSearchField: "false",
        controllerId: null,
        isClickable: "",
        isAddToIndex: false,
        isBulkUploadField: true,
        dateFormat: "DD-MM-YYYY",
        fieldType: "Date",
        textAlign: "center",
        disabled: true,
        show: true,
        field: "updated",
        mobile: true,
        displayInSettings: true,
        tDisplay: true,
      },
      {
        name: "projectName",
        type: "text",
        placeholder: "ProjectName",
        label: "ProjectName",
        width: "130px",
        addFormOrder: 6,
        editFormOrder: 6,
        header: "ProjectName",
        derivedValue: "projectName=undefined",
        capitalizeTableText: false,
        sortable: false,
        isClickable: false,
        filter: false,
        actions: [],
        actionsNumber: [],
        id: "projectName",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinlist: "true",
        isFieldRequired: "false",
        required: false,
        displayOptionsInActions: false,
        globalSearchField: "true",
        controllerId: null,
        textAlign: "Center",
        show: true,
        field: "projectName",
        showOrHideFields: [],
        fieldName: "projectName",
        mobile: true,
        displayInSettings: true,
        fieldType: "Link",
        style: {
          color: "#f17038",
          cursor: "pointer",
          textTransform: "capitalize",
        },
        tDisplay: true,
      },
      {
        name: "projectId",
        fieldName: "projectId",
        type: "relateAutoComplete",
        placeholder: "ProjectId",
        value: "",
        label: "ProjectId",
        width: "150px",
        addFormOrder: 7,
        editFormOrder: 7,
        header: "Project Name",
        derivedValue:
          "projectId=projectId=projectId=projectId=projectId=projectId=undefined",
        capitalizeTableText: false,
        sortable: false,
        filter: false,
        actions: [],
        actionsNumber: [],
        id: "projectId",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinregisterForm: "",
        displayinlist: "true",
        isFieldRequired: "false",
        required: false,
        displayOptionsInActions: false,
        globalSearchField: "false",
        controllerId: 1007,
        isClickable: false,
        isAddToIndex: "",
        isBulkUploadField: false,
        searchField: "name",
        fieldType: "relateAutoComplete",
        populteFields: [],
        displayFields: ["name"],
        controllerName: "Project",
        searchApi: "projects",
        isMultiple: false,
        textAlign: "center",
        show: true,
        field: "projectId",
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
        tDisplay: true,
      },
      {
        name: "Project_name",
        type: "text",
        placeholder: "Project_name",
        label: "Project_name",
        header: "Project name",
        width: 110,
        parentField: "projectId",
        id: "Project_name",
        displayinlist: "true",
        fieldType: "RACSubField",
        textAlign: "Center",
        subFieldName: "name",
        show: true,
        field: "Project_name",
        mobile: true,
        displayInSettings: true,
        tDisplay: true,
      },
      {
        name: "startDate",
        fieldName: "startDate",
        type: "date",
        placeholder: "Start Date",
        value: "",
        label: "Start Date",
        width: "130px",
        addFormOrder: 8,
        editFormOrder: 8,
        header: "Start Date",
        derivedValue: "startDate=undefined",
        capitalizeTableText: false,
        sortable: true,
        filter: true,
        actions: [],
        actionsNumber: [],
        id: "startDate",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinregisterForm: "",
        displayinlist: "true",
        isFieldRequired: "false",
        required: false,
        displayOptionsInActions: false,
        globalSearchField: "false",
        controllerId: null,
        isClickable: false,
        isAddToIndex: false,
        isBulkUploadField: true,
        dateFormat: "DD-MM-YYYY",
        fieldType: "Date",
        textAlign: "center",
        show: true,
        field: "startDate",
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
        tDisplay: true,
      },
      {
        name: "endDate",
        fieldName: "endDate",
        type: "date",
        placeholder: "End Date",
        value: "",
        label: "End Date",
        width: "130px",
        addFormOrder: 5,
        editFormOrder: 5,
        header: "End Date",
        derivedValue: "endDate=endDate=endDate=endDate=endDate=undefined",
        capitalizeTableText: false,
        sortable: false,
        isClickable: false,
        filter: false,
        actions: [],
        actionsNumber: [],
        id: "endDate",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinregisterForm: "",
        displayinlist: "true",
        isFieldRequired: "false",
        required: false,
        displayOptionsInActions: false,
        globalSearchField: "false",
        controllerId: null,
        isClickable: false,
        isAddToIndex: false,
        isBulkUploadField: true,
        dateFormat: "DD-MM-YYYY",
        fieldType: "Date",
        textAlign: "center",
        show: true,
        field: "endDate",
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
        tDisplay: true,
      },
      {
        name: "status",
        fieldName: "status",
        type: "dropDown",
        placeholder: "Status",
        value: "",
        label: "Status",
        width: "130px",
        addFormOrder: 3,
        editFormOrder: 3,
        header: "Status",
        derivedValue:
          "status=status=status=status=status=status=status=undefined",
        capitalizeTableText: false,
        sortable: true,
        filter: true,
        actions: [],
        actionsNumber: [],
        id: "status",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinregisterForm: "",
        displayinlist: "true",
        isFieldRequired: "false",
        required: false,
        displayOptionsInActions: true,
        globalSearchField: "true",
        controllerId: null,
        isClickable: false,
        isAddToIndex: false,
        isBulkUploadField: true,
        options: [
          { label: "In Progress", value: "In Progress", color: "primary" },
          { label: "In Progress", value: "In Progress", color: "primary" },
          { label: "Completed", value: "Completed", color: "primary" },
        ],
        fieldType: "dropDown",
        filterElement: [
          { label: "In Progress", value: "In Progress", color: "primary" },
          { label: "In Progress", value: "In Progress", color: "primary" },
          { label: "Completed", value: "Completed", color: "primary" },
        ],
        // dependent: [],
        textAlign: "center",
        show: true,
        field: "status",
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
        tDisplay: true,
      },
      {
        name: "tasks",
        type: "relateAutoComplete",
        placeholder: "Tasks",
        label: "Tasks",
        width: "150px",
        addFormOrder: 11,
        editFormOrder: 11,
        header: "Tasks",
        derivedValue: "tasks=undefined",
        capitalizeTableText: false,
        sortable: false,
        isClickable: false,
        filter: false,
        actions: [],
        actionsNumber: [],
        id: "tasks",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinlist: "true",
        isFieldRequired: "false",
        required: false,
        displayOptionsInActions: false,
        globalSearchField: "false",
        controllerId: 1008,
        searchField: "taskSummary",
        fieldType: "relateAutoComplete",
        populteFields: [],
        displayFields: [],
        controllerName: "Task",
        searchApi: "tasks",
        isMultiple: true,
        textAlign: "Center",
        show: true,
        field: "tasks",
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
        tDisplay: true,
      },
      {
        name: "assignedTo",
        type: "relateAutoComplete",
        placeholder: "AssignedTo",
        label: "AssignedTo",
        width: "150px",
        addFormOrder: 12,
        editFormOrder: 12,
        header: "AssignedTo",
        derivedValue: "assignedTo=undefined",
        capitalizeTableText: false,
        sortable: false,
        isClickable: false,
        filter: false,
        actions: [],
        actionsNumber: [],
        id: "assignedTo",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinlist: "true",
        displayinaddForm: "false",
        displayineditForm: "false",
        globalSearchField: "false",
        controllerId: 1001,
        searchField: "name",
        fieldType: "relateAutoComplete",
        populteFields: [],
        displayFields: ["name"],
        controllerName: "employee",
        searchApi: "employees",
        isMultiple: false,
        textAlign: "Center",
        show: true,
        field: "assignedTo",
        showOrHideFields: [],
        mobile: true,
        tDisplay: true,
      },
      {
        name: "employee_name",
        type: "text",
        placeholder: "employee_name",
        label: "employee_name",
        header: "employee name",
        width: 110,
        parentField: "assignedTo",
        id: "employee_name",
        displayinlist: "true",
        fieldType: "RACSubField",
        textAlign: "Center",
        subFieldName: "name",
        show: true,
        field: "employee_name",
        mobile: true,
        displayInSettings: true,
        tDisplay: true,
      },
    ];
    return data;
  };

  const getSprintFields = () => {
    let data = [
      {
        show: true,
        mobile: true,
        textAlign: "center",
        width: 47,
        field: "Check Box",
        fieldType: "multiple",
        header: "",
        selectionMode: "multiple",
      },
      {
        show: true,
        mobile: true,
        textAlign: 'center',
        width: 50,
        field: 'Sno',
        fieldType: 'text',
        type: 'text',
        header: 'SNo',
        filter: true,
        sortable: false,
      },
      {
        show: true,
        mobile: true,
        textAlign: 'center',
        width: 90,
        field: 'taskID',
        fieldType: 'text',
        type: 'text',
        header: 'Task ID',
        filter: true,
        sortable: true,
      },
      {
        show: true,
        mobile: true,
        textAlign: 'left',
        width: 350,
        field: 'name',
        header: 'Task Name',
        filter: true,
        sortable: true,
        style: { width: '250px', textOverflow: 'ellipsis' }
      },
      {
        show: true,
        mobile: true,
        textAlign: 'center',
        width: 50,
        fieldType: 'Number',
        field: 'estimatedHours',
        header: 'E-Hours',
        filter: true,
        sortable: true
      },
      {
        show: true,
        mobile: true,
        textAlign: 'center',
        width: 50,
        fieldType: 'Number',
        field: 'priorityNumber',
        header: 'PriorityNo',
        filter: true,
        sortable: true
      },
      {
        show: true,
        mobile: true,
        textAlign: 'center',
        width: 50,
        fieldType: 'Number',
        field: 'actualWorkingHours',
        header: 'W-Hours',
        filter: true,
        sortable: true
      },
      {
        show: true,
        mobile: true,
        width: 50,
        dateFormat: "DD-MM-YYYY",
        fieldType: "Date",
        textAlign: "Center",
        field: 'endDate',
        header: 'EndDate',
        filter: true,
        sortable: true
      },
      {
        show: true,
        mobile: true,
        textAlign: 'left',
        width: 250,
        fieldType: 'text',
        field: 'assignedToName',
        header: 'AssignedTo',
        filter: true,
        sortable: true
      },
      {
        show: true,
        mobile: true,
        textAlign: 'center',
        width: 150,
        fieldType: 'Array',
        field: 'taskStatus',
        header: 'Status',
        filter: true,
        sortable: true,
        options: [
          { value: "new", color: "#326fd1" }, { value: "in development", color: "#9fa9b7" },
          { value: "dev completed", color: "#198754" }, { value: "analysis/design", color: "#00FF00" },
          { value: "review completed", color: "dark" }, { value: "ready for testing", color: "#eec137", },
          { value: "need clarification", color: "#FF00FF" }, { value: "reopen", color: "red" },
          { value: "resolved", color: "#0dcaf0" }, { value: "moved to prod", color: "#00FFFF" },
          { value: "onhold", color: "#000000" }, { value: "future implementation", color: "#000080" },
          { value: "unable to replicate", color: "#A52A2A" }, { value: "duplicate", color: "#808080" },
          { value: "not an issue", color: "#FFA500" },
        ],
      },
      {
        show: true,
        mobile: true,
        textAlign: 'left',
        width: 250,
        fieldType: 'Array',
        field: 'reopenComments',
        header: 'Reopen Comments',
        filter: true,
        sortable: true
      },
      {
        show: true,
        mobile: true,
        textAlign: 'center',
        width: 250,
        fieldType: 'number',
        field: 'taskCompletionPercent',
        header: 'Percentage of Task Completed',
        filter: true,
        sortable: true
      },
    ];
    return data;
  }

  const closeFormModal = async () => {
    setIsOpenFormModal(false);
  };

  const cancelReset = async (type) => {
    setopenNewUserModal(false);
    // window.location.reload();
    if (dataTableRef.current) {
      const { getDataFromServer, filterCriteria } = await dataTableRef.current;
      getDataFromServer(filterCriteria);
    }
  };

  const saveDataToServer = async (item, field, value, getDataFromServer) => {
    let userBody;
    if (item?.length > 0 && field && value) {
      let selectedIds = item.map((row) => row._id);
      userBody = {
        selectedIds: selectedIds,
        updatedDetails: { [field]: value },
      };
    } else {
      return;
    }
    if (userBody) {
      let method, apiUrl;
      method = "POST";
      apiUrl = `${apiCalls.sprints}/multiUpdate`;
      return fetchMethodRequest(method, apiUrl, userBody)
        .then(async (response) => {
          if (response && response.respCode) {
            showToasterMessage(response.respMessage, "success");
            getDataFromServer();
          } else if (response && response.errorMessage) {
            showToasterMessage(response.errorMessage, "error");
          }
        })
        .catch((err) => {
          return err;
        });
    } else {
      return;
    }
  };

  const isEditPermission = rolePermission?.type == "Edit" ? true : false;
  const actionTypes = [
    {
      name: "Delete",
      options: [
        {
          label: "Delete",
          value: "Delete",
          show: rolePermission?.actions?.includes("Delete") ? true : false,
          multiple: true,
        },
      ],
    },
    {
      name: "Not Started",
      action: saveDataToServer,
      options: [
        {
          label: "Not Started",
          field: "status",
          value: "Not Started",
          show: rolePermission?.actions?.includes("Approve/Reject") ? true : false,
          multiple: false,
        },
      ],
    },
    {
      name: "In Progress",
      action: saveDataToServer,
      options: [
        {
          label: "In Progress",
          field: "status",
          value: "In Progress",
          show: rolePermission?.actions?.includes("Approve/Reject") ? true : false,
          multiple: false,
        },
      ],
    },
    {
      name: "Dev Completed",
      action: saveDataToServer,
      options: [
        {
          label: "Dev Completed",
          field: "status",
          value: "Dev Completed",
          show: rolePermission?.actions?.includes("Approve/Reject") ? true : false,
          multiple: false,
        },
      ],
    },
    {
      name: "Completed",
      action: saveDataToServer,
      options: [
        {
          label: "Completed",
          field: "status",
          value: "Completed",
          show: rolePermission?.actions?.includes("Approve/Reject") ? true : false,
          multiple: false,
        },
      ],
    },
  ];

  return (
    <span>
      {
        <DataTables
          onRef={(ref) => (dataTableRef.current = ref)}
          {...props}
          getTableFields={getTableFields}
          formFields={getFormFields}
          //
          addRequired={isEditPermission}
          editRequired={isEditPermission}
          deleteRequired={isEditPermission}
          viewRequired={isEditPermission}
          exportRequired={isEditPermission}
          sample={true}
          PrintRequired={isEditPermission}
          // globalSearch={'Display Name/Email'}
          // type='Sprints'
          // apiUrl={apiCalls.Sprints}
          // exportRequried={true}
          printRequried={true}
          actionsTypes={actionTypes}
          // addRequried={insertAdd}
          // editRequired={true}
          // deleteRequired={true}
          // viewRequired={true}
          settingsRequired={true}
          filterRequired={false}
          gridRequried={true}
          exportToCsv={true}
          dateSearchRequired={false}
          searchInDateRangeField={"created"}
          setData={setData}
          editSelectedRecord={editSelectedRecord}
          addSelectedRecord={addSelectedRecord}
          viewSelectedRecord={viewSelectedRecord}
          sampleFilePath={filePath.sprints}
          globalSearch={"sprintName/projectName/status"}
          displayName="Sprints"
          type="Sprints"
          routeTo={apiCalls.sprints}
          displayViewOfForm="sideForm"
          sideFormLeftOrRight="right"
          apiResponseKey={apiCalls.sprints?.toLowerCase()}
          apiUrl={apiCalls.sprints}
          selectedId={params.id}
          displayActionsInTable={"actions"}
          getSprintFields={getSprintFields}
        />
      }
      {isOpenFormModal ? (
        <FormModal
          // onRef={(ref) => (this.formModalRef = ref)}
          formType="edit"
          openFormModal={isOpenFormModal}
          formFields={formFields}
        />
      ) : null}
      {openNewUserModal ? (
        <NewUserModal
          openNewUserModal={openNewUserModal}
          cancelReset={cancelReset}
          item={item}
          newFormFields={newFormFields}
          recordId={item._id}
          entityType="sprints"
          apiUrl={apiCalls.sprints}
        />
      ) : null}
    </span>
  );
};

export default Sprints;
