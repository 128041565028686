import React, { useState } from "react";
import { Checkbox } from 'primereact/checkbox';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAsterisk } from "@fortawesome/free-solid-svg-icons";
import AutoLeavesApproveModal from "../../Cruds/CommonModals/AutoLeavesApproveModal";

const DoCheckboxField = (props) => {
    const id = props.id ? props.id : props.name;
    const label = props.label ? props.label : props.name;    
    const name = props.name;   
    let field = props.field;
    const [showModal, setShowModal] = useState(false);
    const [checked, setChecked] = useState(props.field.value);

    const onCheckboxChange = (e) => {
        if( props.screen === "Employees"){
            if (e.checked) {
                setShowModal(true);
            } else {
                setChecked(false);
                field.onChange(e.checked)
            }
        }else{
            field.onChange(e.checked)
            if(props.onChangeFn){
                props.onChangeFn(e);
            }
        }

    };

    const onModalConfirm = () => {
        setChecked(true);
        props.field.onChange(true);
        setShowModal(false);
    };

    const onModalClose = () => {
        setShowModal(false);
    };

    let markRequired = () => {
        return (
            <FontAwesomeIcon
                color='red'
                icon={faAsterisk}
                style={{ width: '7px',marginBottom:"5px",marginLeft:"2px" }}
            />
        );
    };

    return (
        <div className="flex flex-column pt-1">
            <div className="flex align-items-center">
                <Checkbox
                    inputId={field.name}
                    checked={field.value}
                    inputRef={field.ref}
                    name={field.name}
                    onChange={onCheckboxChange} />
                <div className="flex align-center"> 
                    <label htmlFor={id} className="text-capitalize"  style={{ paddingLeft: '6px' }}>{label}</label>
                    {props.markReq && props.markReq === true && markRequired()}
                </div>
            </div>
            <small className="text-danger " style={{ marginTop: "5px" }}>{props.fieldState.invalid ? props.errors[props.name]?.message : ''}</small>

            {/* Modal for confirmation */}
            <AutoLeavesApproveModal 
                openConfirmationModal={showModal} 
                onConfirm={onModalConfirm} 
                onClose={onModalClose} 
            />
        </div>
    )

}

export default DoCheckboxField;